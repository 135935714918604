import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//import { Link } from "gatsby"

import Layout from "../components/layout";
//import Image from "../components/image"
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Singapore Company Incorporation" />
    <Container>
    <h2 className="logo text-center text-lg-right mb-5">Jozef</h2>
      <Row>
        <Col>
          <h1 className="mb-5">Singapore Company Incorporation</h1>
          <h2>No hidden costs</h2>
          <p className="mb-5">
            Government fees for incorporation and name-checking are included.
          </p>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={7}>
                  <h3>Incorporation</h3>
                </Col>
                <Col xs={5} className="text-right">
                  <h3>S$1,999</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Includes full service for 1 year:</p>
                  <ul>
                    <li>Unlimited Company Secretary</li>
                    <li>Nominee Director</li>
                    <li>Registered address</li>
                    <li>Company registration</li>
                  </ul>
                </Col>
              </Row>
              <div className="separator">
                <Row>
                  <Col xs={1}>
                    <div className="plus">
                      <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path
                          fill="#8094c7"
                          d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z"
                        />
                      </svg>
                    </div>
                  </Col>
                  <Col xs={10} className="pr-0">
                    <hr />
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={7}>
                  <h3>Accounting</h3>
                </Col>
                <Col xs={5} className="text-right">
                  <h3>
                    S$99<small>/month</small>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    A must-have with the Nominee director service. Covers 1 year
                    of bookkeeping, accounting, and tax.
                  </p>
                </Col>
              </Row>
              <div className="mt-4">
                <SignUpForm />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
);

function SignUpForm() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="primary"
        size="lg"
        block
        onClick={() => setOpen(!open)}
        aria-controls="collapse-content"
        aria-expanded={open}
      >
        Get Started
      </Button>
      <Collapse in={open} className="mt-4">
        <div id="collapse-content">
          <p className="mb-4">
            Please fill out the form below. Our team will be in touch with you
            shortly.
          </p>
          <Form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="jozef-regform"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="jozef-regform" />

            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                id="firstName"
                name="firstName"
                type="text"
                placeholder="John"
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Doe"
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder="example@company.com"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Button type="submit" variant="secondary" size="lg" block>
              Submit
            </Button>
          </Form>
        </div>
      </Collapse>
    </>
  );
}

export default IndexPage;
